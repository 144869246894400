$color-white: #fff;
$color-gray-light: #f2f2f2;
$color-gray-mid: #ddd;
$color-gray-light: #efefef;
$color-gray-dark: rgba(117, 117, 117, 1);
$color-gray-progressbar: #ABABAB;
$color-gray-border-checkout: #E8E8E8;
$color-black: #000;
$color-red: #f5333f;
$color-blue: #3cc8e1;
$color-blue-light: #f2fcff;
$color-blue-2: #00B9DF;
$color-blue-progressbar: #3EC8E0;
$color-blue-secondary: #007db3;
$color-inky-black: #010042;
$color-text-secondary: #757575;
$color-orange: #f6ae40;
$color-mid-orange: #f2ba00;
$color-f1: #F1F1F1;
$color-c6: #C6C6C6;
$color-e6: #e6e6e6;
$color-purple: #c964cf;
$color-green: #14bfa1;
$color-pink: #e60075;
$color-medium-pink: #cf085e;

$color-navy: #001a70;
$color-dark-grey-blue: #2C3D56;

$color-yellow: #ffe600;



$color-background: $color-white;
$color-background-2: $color-gray-light;
$color-background-3: $color-gray-mid;
$color-text: $color-black;
$color-text-2: $color-gray-dark;
$color-line: $color-black;
$color-line-2: $color-gray-dark;
$color-line-3: $color-gray-mid;

$measure: 80ch;


//Variables from removing bootstrap
$enable-pointer-cursor-for-buttons: true !default;
$label-margin-bottom: .5rem !default;
$gray-600: #6c757d !default;
$text-muted: $gray-600 !default;
$table-caption-color: $text-muted !default;
$table-cell-padding: .75rem !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$gray-900: #212529 !default;
$body-bg: #FFF !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$link-color: #000;
$link-decoration: none !default;
$link-hover-decoration: underline !default;
$link-hover-color: $gray-900 !default;
$dt-font-weight: $font-weight-bold !default;
$font-size-base: 1rem !default;
$font-weight-normal: 400 !default;
$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;
$gray-900: #212529 !default;
$body-color: $gray-900 !default;
$spacer: 1rem !default;
$headings-margin-bottom: calc($spacer / 2) !default;
$paragraph-margin-bottom: 1rem !default;

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-column-center {
  @include flex-column;
  justify-content: center;
  align-items: center;
}

@mixin flex-row-center {
  @include flex-row;
  justify-content: center;
  align-items: center;
}

@mixin font-medium {
  font-weight: 100;
  font-family: SourceSans3-SemiBold, SourceSans3-Regular-Medium, sans-serif;
}

@mixin font-bold {
  font-weight: 100;
  font-family: SourceSans3-Bold, SourceSans3-Regular-Bold, sans-serif;
}

@mixin font-light {
  font-family: SourceSans3-Regular, SourceSans3-Regular, sans-serif;
}

@mixin font-x-light {
  font-family: SourceSans3-Regular, SourceSans3-Regular, sans-serif;
  font-weight: 100;
}

@mixin font-x-big-dynamic {
  font-size: clamp(24pt, calc(-3pt + 6.5vw), 38pt);
  letter-spacing: -1.5px;
  color: $color-inky-black;
}

@mixin font-big-dynamic {
  font-size: clamp(20pt, calc(-1pt + 3.5vw), 30pt);
  letter-spacing: -1.5px;
  color: $color-inky-black;
}

@mixin font-medium-dynamic {
  font-size: clamp(14pt, calc(-1pt + 3.5vw), 20pt);
  letter-spacing: -0.5px;
  color: $color-inky-black;
}

@mixin secondary-font {
  font-family: GBC-Core, GBC, Book;
}

@mixin border-b($border-width) {
  border: $border-width solid $color-gray-mid;
}

@mixin border-w($border-width) {
  border: $border-width solid $color-white;
}

@mixin divider($min-height) {
  min-height: $min-height;
  height: $min-height;
  width: 100%;
  background-color: $color-black;
}

@mixin divider-v($min-width) {
  min-width: $min-width;
  width: $min-width;
  height: 100%;
  min-height: 100%;
  background-color: $color-black;
}

@mixin backdrop() {
  @include flex-column;
  position: fixed;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.16);
  z-index: 15;
}

@mixin nth-line-text($line) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line; /* number of lines to show */
  line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin inky-button() {
  background-color: $color-pink;
  color: $color-white;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

@mixin inky-button-secondary() {
  background-color: $color-f1;
  color: $color-black;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 200ms, color 200ms;

  &:hover {
    background-color: $color-pink;
    color: $color-white;
  }

  &:disabled {
    cursor: default;
  }
}

@mixin pointer() {
  &:hover {
    cursor: pointer;
  }
}

@mixin colors() {
  background-color: chocolate;
}

@mixin tiny-scroll() {
  scroll-behavior: smooth;
  scrollbar-color: $color-pink transparent;
  scrollbar-width: thin;


  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-black;
    border-radius: 5px;
  }

}

@mixin reader-scroll() {
  scroll-behavior: smooth;


  &::-webkit-scrollbar-track {
    border-radius: 100px;
    background-color: black;
  }

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 100px;

  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(white,0.3);
    width: 4px;
    border-radius: 100px;
  }

  scrollbar-color: rgba(white,0.5) #0C0C1D;
  scrollbar-width: thin;

}

@mixin reader-scroll-light() {
  scroll-behavior: smooth;


  &::-webkit-scrollbar-track {
    border-radius: 100px;
    background-color: black;
  }

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 100px;

  }

  &::-webkit-scrollbar-thumb {
    background-color: #0C0C1D;
    width: 4px;
    border-radius: 100px;
  }

  scrollbar-color: transparent;
  scrollbar-width: thin;

}


@mixin router_link() {
  //color: $color-black;
  text-decoration: none;

  &:hover {
    //color: $color-black;
    text-decoration: none;
  }
}